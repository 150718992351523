<template>
  <section id="filters-header" class="mb-4">
    <h5>
      <feather-icon
        v-if="icon && filterTitle"
        icon="FilterIcon"
        svgClasses="h-4 w-4 ml-1"
      />
      {{ filterTitle }}
    </h5>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'FiltersHeader',
  props: {
    title: {
      type: Boolean,
      default: true
    },
    icon: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    filterTitle() {
      return this.title
        ? this.lang.metrics.filters.title[this.languageSelected]
        : ''
    }
  }
}
</script>
